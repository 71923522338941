import Swal from 'sweetalert2';
import config from '../config/app';

export function showLogin() {
  Swal.fire({
    title: `<span class="popup-alert__title">ประกาศ</span>`,
    html: `
      <div class="popup-alert__content">
        <h5 class="popup-alert__message">โปรดล็อกอินเพื่อร่วมกิจกรรม</h5>
      </div>
    `,
    showConfirmButton: false,
    showCancelButton: false,
    showCloseButton: true,
    customClass: {
      popup: 'popup-alert popup-error'
    },
    showClass: {
      popup: 'animate__animated animate__fadeInDown animate__faster'
    },
    hideClass: {
      popup: 'animate__animated animate__fadeOutUp animate__faster'
    },
  });
}

export function showError(code, title = 'ประกาศ') {
  let msg = '';

  switch (code) {
    case 'event_ended':
      msg = 'กิจกรรมสิ้นสุดลงแล้ว';
      break;

    case 'missing_tencent_id':
      msg = 'คุณยังไม่สร้างบัญชี';
      break;

    case 'pool_limit_exceeded':
      msg = 'จำนวนการเล่นครบแล้ว';
      break;

    case 'not_enough_coupon':
      msg = 'คูปองไม่เพียงพอ';
      break;

    case 'transaction_failed':
      msg = 'หักคูปองล้มเหลว โปรดลองใหม่อีกครั้ง';
      break;

    default:
      msg = 'ทำรายการล้มเหลว โปรดลองใหม่อีกครั้ง';
      break;
  }

  return Swal.fire({
    title: `<span class="popup-alert__title">${title}</span>`,
    html: `
      <div class="popup-alert__content">
        <h5 class="popup-alert__message">${msg}</h5>
      </div>
    `,
    showConfirmButton: false,
    showCancelButton: false,
    showCloseButton: true,
    closeButtonHtml: `<img src="${config.cdn}/img/icon-close.png" alt="ปิด" />`,
    customClass: {
      popup: 'popup-alert popup-error'
    },
    showClass: {
      popup: 'animate__animated animate__fadeInDown animate__faster'
    },
    hideClass: {
      popup: 'animate__animated animate__fadeOutUp animate__faster'
    },
  })
}

export function showSuccess(code, data = {}, callback = () => {}, title = 'ยินดีด้วย!') {
  let content = '';

  switch (code) {
    case 'receive_gift':
      content = `
        <div class="popup-alert__content">
          <div class="popup-alert__gift">
            <div class="popup-alert__gift--image">
              <img src="${data.image}" alt="${data.name}"/>
              <span>${data.description}</span>
            </div>
            <div class="popup-alert__gift--message">คุณได้ ${data.weight} คะแนน และได้รับ</div>
            <div class="popup-alert__gift--name">${data.name}</div>
          </div>
        </div>
      `;
      break;

    case 'receive_jackpot':
      content = `
        <div class="popup-alert__content">
          <div class="popup-alert__gift">
            <div class="popup-alert__gift--image">
              <img src="${data.image}" class="popup-alert__gift--image"/>
            </div>
            <div class="popup-alert__gift--message">คุณปรับสมดุลสำเร็จ ได้รับรางวัลใหญ่ทั้งหมด</div>
          </div>
        </div>
      `;
      break;

    default:
      content = `
        <div class="popup-alert__content">
          <h5 class="popup-alert__message">หักคููปองสำเร็จ</h5>
        </div>
      `;
      break;
  }

  return Swal.fire({
    title: `<span class="popup-alert__title">${title}</span>`,
    html: content,
    showConfirmButton: false,
    showCancelButton: false,
    showCloseButton: true,
    closeButtonHtml: `<img src="${config.cdn}/img/icon-close.png" alt="ปิด" />`,
    customClass: {
      popup: 'popup-alert popup-success'
    },
    showClass: {
      popup: 'animate__animated animate__fadeInDown animate__faster'
    },
    hideClass: {
      popup: 'animate__animated animate__fadeOutUp animate__faster'
    },
    onClose: () => {
      callback();
    }
  })
}

export function showConfirm(msg = '', callback = () => {}, title = 'ประกาศ') {
  window.handleNotAskAgain = function(e) {
    localStorage.setItem('not_ask_again', `${e.checked}`);
  };

  return Swal.fire({
    title: title,
    html: `
      <div class="popup-alert__content">
        <h5 class="popup-alert__message">${msg}</h5>
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" id="notAskAgain" onChange="handleNotAskAgain(this)">
          <label class="custom-control-label" for="notAskAgain">ไม่ต้องแสดงหน้าต่างนี้อีก</label>
        </div>
      </div>
    `,
    confirmButtonText: 'ยืนยัน',
    showCancelButton: true,
    cancelButtonText: 'ยกเลิก',
    showCloseButton: true,
    closeButtonHtml: `<img src="${config.cdn}/img/icon-close.png" alt="ปิด" />`,
    target: 'body',
    reverseButtons: true,
    customClass: {
      popup: 'popup-alert popup-confirm'
    },
    showClass: {
      popup: 'animate__animated animate__fadeInDown animate__faster'
    },
    hideClass: {
      popup: 'animate__animated animate__fadeOutUp animate__faster'
    },
  }).then(res => {
    if (res.value) {
      callback();
    }
  });
}

export function toObj(array, key) {
  var result = array.reduce(function(map, obj) {
    map[obj[key]] = obj;
    return map;
  }, {});

  return result;
}

export function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }

  return true;
}
