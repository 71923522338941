import request from 'lib/request';
import { showError, showSuccess } from '../lib/commons';
import config from '../config/app';

export const CURRENT_USER_REQUEST_ERROR   = 'CURRENT_USER_REQUEST_ERROR';
export const CURRENT_USER_REQUEST_SUCCESS = 'CURRENT_USER_REQUEST_SUCCESS';
export const CURRENT_USER_FETCHING        = 'CURRENT_USER_FETCHING';

export const PLAY_ERROR   = 'PLAY_ERROR';
export const PLAY_SUCCESS = 'PLAY_SUCCESS';
export const IS_PLAYING   = 'IS_PLAYING';

// USER
export const isCurrentUserFetching = () => {
  return {
    type: CURRENT_USER_FETCHING
  };
}

export const getCurrentUser = () => {
  return (dispatch, getState) => {
    dispatch(isCurrentUserFetching());
    request('api/user/get').then(function(response) {
      if(response.status == 'successful') {
        dispatch(getCurrentUserSuccess(response));
      } else {
        dispatch(getCurrentUserError(response));
      }
    });
  };
}

export const getCurrentUserSuccess = (response) => {
  return {
    type: CURRENT_USER_REQUEST_SUCCESS,
    payload: response.payload
  };
}

export const getCurrentUserError = (response) => {
  return {
    type: CURRENT_USER_REQUEST_ERROR,
    payload: response.payload
  };
}

// PLAY
export const isPlaying = () => {
  return {
    type: IS_PLAYING
  }
}

export const play = (pool) => {
  return (dispatch, getState) => {
    dispatch(isPlaying());
    request('api/user/play', 'POST', {
      body: JSON.stringify({ pool })
    }).then(function(response) {
      if (response.status == 'successful') {
        const {
          jackpots,
          old_balance_weight_left,
          old_balance_weight_right,
          reward: {name, image, description},
          user: { balance_weight_left, balance_weight_right },
        } = response.payload;
        const weight = (pool === 1) ? balance_weight_left - old_balance_weight_left : balance_weight_right - old_balance_weight_right;
        dispatch(playSuccess(response));
        setTimeout(() => {
          if (jackpots.length) {
            showSuccess('receive_jackpot', {
              image: `${config.cdn}/img/prize-3.png`
            }, () => showSuccess('receive_gift', { name, image, description, weight }));
          } else {
            showSuccess('receive_gift', { name, image, description, weight });
          }
        }, 600);
      } else {
        dispatch(playError(response));
        showError(response.error_code);
      }
    })
  }
}

export const playSuccess = (response) => {
  return {
    type: PLAY_SUCCESS,
    payload: response.payload
  }
}

export const playError = (response) => {
  return {
    type: PLAY_ERROR,
    payload: response.payload
  }
}
