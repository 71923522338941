import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getCurrentUser } from 'authentication/actions';

const PageLayout = ({currentUser, location, children, check, getCurrentUser}) => {
  useEffect(() => {
    getCurrentUser();
  }, []);

  return (
    <>
      <main id="main" className={`page-${location.pathname !== '/' ? location.pathname.replace('/', '') : 'home'}`}>
        <>
          {children}
        </>
      </main>
    </>
  );
};

const mapDispatchToProps = {
  getCurrentUser
};

const mapStateToProps = (state) => ({
  currentUser: state.currentUser
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PageLayout));
