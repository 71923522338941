import {
  CURRENT_USER_REQUEST_ERROR,
  CURRENT_USER_REQUEST_SUCCESS,
  CURRENT_USER_FETCHING,

  PLAY_ERROR,
  PLAY_SUCCESS,
  IS_PLAYING,
} from './actions';

const initialState = {
  loading: false,
  login: false,
  user: {},
  prices: {},
  isPlaying: false,
  rule: '',
};

export default function currentUserReducer(state = initialState,  { type, payload } = action) {
  switch (type) {
    // USER
    case CURRENT_USER_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        login: true,
        user: payload.user,
        prices: payload.prices,
        rule: payload.rule,
        errorGlobal: ''
      };
      break;
    case CURRENT_USER_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        errorGlobal: 'Not Found User',
      };
      break;
    case CURRENT_USER_FETCHING:
      return {
        ...state,
        loading: true
      };
      break;

    // PLAY
    case PLAY_SUCCESS:
      return {
        ...state,
        isPlaying: false,
        user: payload.user,
        prices: payload.prices,
      }
      break;
    case PLAY_ERROR:
      return {
        ...state,
        isPlaying: false,
      }
      break;
    case IS_PLAYING:
      return {
        ...state,
        isPlaying: true
      }
      break;

    // DEFAULT
    default:
      return state;
  }
}
